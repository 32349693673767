import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
const Support = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const {i18n}= useTranslation()
      const currentLanguage= i18n.language
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto'>
        <div className="lg:pt-[150px] md:pt-10 xs:pt-9 flex justify-center items-start flex-col text-justify xs:text-[13px] md:text-[17px] md:mx-[70px] xs:mx-5 font-roboto">
        <h2 className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto font-bold  md:my-5 xs:my-4 underline underline-offset-[12px] decoration-bbf-green">
       {currentLanguage==="de"?"Serviceabwicklung":currentLanguage==="pl"?"Serwis":"Serviceabwicklung"} 
          </h2>
          <h2 className='text-bbf-green font-bold'>{currentLanguage==="de"?"Wir bitten, im Falle einer Reklamation Folgendes zu beachten:":currentLanguage==="pl"?"W przypadku reklamacji prosimy o zwrócenie uwagi na poniższe zasady:":"Wir bitten, im Falle einer Reklamation Folgendes zu beachten:"}</h2> <br/>
   <h3 className='mt-3 '>
   <h2 className='text-bbf-green font-bold'>{currentLanguage==="de"?"1. Teile & Zubehör":currentLanguage==="pl"?"1. Części i akcesoria":"1. Teile & Zubehör"}</h2> <br/>
   {currentLanguage==="de"?(<p>Über die Verknüpfung in Ihrem Kundenkonto gelangen Sie auf unsere separate Website für Reklamationen: <b>https://reklamation.bbf-bike.de </b> 
   Für eine schnelle Abwicklung füllen Sie alle Datenfelder bestmöglich aus, die mit * gekennzeichneten Felder sind Pflicht.  Alternativ senden Sie bitte eine Mail an <b>reklamation@bbf-bike.de </b> <br/></p>):currentLanguage==="pl"?(<p>Poprzez link na koncie klienta przejdziesz do naszej oddzielnej strony internetowej dotyczącej reklamacji: <b>https://reklamation.bbf-bike.de </b> 
   Aby zapewnić szybkie przetwarzanie danych, prosimy o wypełnienie dokładnie wszystkich pól; pola oznaczone * są obowiązkowe. Ewentualnie wszystkie informacje można przesłać wiadomością e-mail na adres <b>reklamation@bbf-bike.de </b> <br/></p>):(<p>Über die Verknüpfung in Ihrem Kundenkonto gelangen Sie auf unsere separate Website für Reklamationen: <b>https://reklamation.bbf-bike.de </b> 
   Für eine schnelle Abwicklung füllen Sie alle Datenfelder bestmöglich aus, die mit * gekennzeichneten Felder sind Pflicht.  Alternativ senden Sie bitte eine Mail an <b>reklamation@bbf-bike.de </b> <br/></p>)}
   
   - {currentLanguage==="de"?"Angabe Ihrer Kundennummer- Welcher Artikel wird reklamiert (Artikelnummer)":currentLanguage==="pl"?"podać numer klienta - który artykuł jest przedmiotem reklamacji (numer artykułu)":"Angabe Ihrer Kundennummer- Welcher Artikel wird reklamiert (Artikelnummer)"} <br/>
   - {currentLanguage==="de"?"Aussagekräftige Fehlerbeschreibung (defekt ist keine Fehlerbeschreibung)":currentLanguage==="pl"?"sensowny i odpowiedni opis błędu (uszkodzony nie jest opisem błędu)":"Aussagekräftige Fehlerbeschreibung (defekt ist keine Fehlerbeschreibung)"} <br/>
   - {currentLanguage==="de"?"Aussagekräftige Detailfotos und ein komplettes Foto vom jeweiligen Produkt ":currentLanguage==="pl"?"znaczące, szczegółowe zdjęcia uszkodzenia ikzdjęcie całego produktu":"Aussagekräftige Detailfotos und ein komplettes Foto vom jeweiligen Produkt "}<br/>
   - {currentLanguage==="de"?"Eine Kopie der Endkundenrechnung wird für die Bearbeitung jeder Reklamation benötigt.":currentLanguage==="pl"?"do rozpatrzenia każdej reklamacji posprzedażowej wymagana jest koniecznie kopia faktury klienta detalicznego":"Eine Kopie der Endkundenrechnung wird für die Bearbeitung jeder Reklamation benötigt."}<br/>
   {currentLanguage==="de"?"Alternativ können Sie die Reklamation auch direkt bei Ihrem Außendienst oder auch per Telefon in jeder":currentLanguage==="pl"?"Alternatywnie reklamację można zarejestrować reklamację bezpośrednio u przedstawiciela handlowego.":"Alternativ können Sie die Reklamation auch direkt bei Ihrem Außendienst oder auch per Telefon in jeder"} <br/>
   {currentLanguage==="de"?"Filiale anmelden. Eine Reklamationsbearbeitung ohne Verkaufsbeleg ist nicht möglich!":currentLanguage==="pl"?"Reklamacje bez dowodu zakupu i zgłoszenia nie będą rozpatrywane! ":"Filiale anmelden. Eine Reklamationsbearbeitung ohne Verkaufsbeleg ist nicht möglich!"} <br/>
  <h2 className='flex justify-start items-start gap-2 mt-4'><p className='text-bbf-green font-bold'>2.</p>{currentLanguage==="de"?"Falsch gelieferte Fahrräder sind grundsätzlich in unbeschädigter Originalverpackung und einwandfreiem Zustand (wie im Lieferzustand) zur Abholung bereitzustellen! ":currentLanguage==="pl"?"Nieprawidłowo dostarczone rowery muszą być wysłane lub przygotowane do odbioru w nieuszkodzonym oryginalnym opakowaniu i w idealnym stanie (w jakim zostały dostarczone)!":"Falsch gelieferte Fahrräder sind grundsätzlich in unbeschädigter Originalverpackung und einwandfreiem Zustand (wie im Lieferzustand) zur Abholung bereitzustellen! "}</h2>  <br/>
  <h2 className='flex justify-start items-start gap-2'><p className='text-bbf-green font-bold'>3.</p>{currentLanguage==="de"? "Die für die Rückgabe bestimmten Fahrräder sind transportfähig zu demontieren, d.h. es müssen die Pedale abgeschraubt, der Lenker in Fahrtrichtung gedreht und das Fahrrad mit einer Transportverpackung versehen werden (in den Lieferzustand zurück gesetzt werden). Zusätzlich ist der Original-Verkaufsbeleg und das Übergabeprotokoll (Rückgabeschein) erforderlich.":currentLanguage==="pl"?"Rowery przeznaczone do zwrotu muszą być zdemontowane i przygotowane do transportu, tj. pedały muszą być odkręcone, kierownica musi być obrócona w kierunku jazdy, a rower musi znajdować się w opakowaniu transportowym (przywrócenie do stanu dostawy). Ponadto wymagany jest oryginalny dowód sprzedaży, faktura i protokół przekazaniaroweru (potwierdzenie zwrotu).":"Die für die Rückgabe bestimmten Fahrräder sind transportfähig zu demontieren, d.h. es müssen die Pedale abgeschraubt, der Lenker in Fahrtrichtung gedreht und das Fahrrad mit einer Transportverpackung versehen werden (in den Lieferzustand zurück gesetzt werden). Zusätzlich ist der Original-Verkaufsbeleg und das Übergabeprotokoll (Rückgabeschein) erforderlich."}</h2>  <br/>
  <h2 className='flex justify-start items-start gap-2'><p className='text-bbf-green font-bold'>4.</p>{currentLanguage==="de"?"Fahrräder, die zur Reparatur in Folge einer Reklamation zu uns zurückgeschickt werden, sind uns im gesäuberten Zustand zu übergeben. Die Berechnung der Reinigungskosten bleibt uns vorbehalten.":currentLanguage==="pl"?"Rowery zwracane do nas w celu naprawy w następstwie reklamacji muszą być zwrócone w stanie czystym. Zastrzegamy sobie prawo do naliczenia kosztów czyszczenia.":"Fahrräder, die zur Reparatur in Folge einer Reklamation zu uns zurückgeschickt werden, sind uns im gesäuberten Zustand zu übergeben. Die Berechnung der Reinigungskosten bleibt uns vorbehalten."} </h2>  <br/>
   
   <h2 className='flex justify-start items-start gap-2'><p className='text-bbf-green font-bold'>5.</p>{currentLanguage==="de"?"Alle nachträglich angebrachten Teile (Schlösser, Kindersitzhalter, Computer, Beleuchtung etc.) sind zu entfernen. Bei Nichtbeachtung ist ein möglicher Verlust der Ware vom Kunden selbst zu tragen.":currentLanguage==="pl"?"Wszystkie dodatkowe części (zamki, uchwyty na foteliki dziecięce, komputery, oświetlenie itp.) muszą zostać usunięte . W przypadku nieprzestrzegania tego wymogu wszelkie straty związane z towarem ponosi klient.":"Alle nachträglich angebrachten Teile (Schlösser, Kindersitzhalter, Computer, Beleuchtung etc.) sind zu entfernen. Bei Nichtbeachtung ist ein möglicher Verlust der Ware vom Kunden selbst zu tragen."}</h2>  <br/>
   <h3 className='text-bbf-green font-bold'>{currentLanguage==="de"?"BBF BIKE Ansprechpartner für Reklamationen":currentLanguage==="pl"?"Osoby kontaktowe BBF BIKE do spraw reklamacji":"BBF BIKE Ansprechpartner für Reklamationen"}</h3>
   <p>Mail reklamation@bbf-bike.de</p>
   </h3>
   </div>
   <div className='flex justify-start items-center font-roboto xs:gap-8 md:gap-12 xl:gap-20 xs:my-8 xl:my-20 xs:text-[13px] md:text-[17px] md:mx-[70px] xs:mx-5 '>
     <h2>
        <p className='text-bbf-green font-bold'>LARS HEIN</p>
        <p className='font-bold'>Reklamation </p>
        <p className='font-bold'>Ersatzteile & Zubehör</p>
        <p className='font-bold'>Tel (+49) 03342.354 366</p>
     </h2>
     <h2>
        <p className='text-bbf-green font-bold'>CHRISTIAN FERCHLAND</p>
        <p className='font-bold'>Reklamation </p>
        <p className='font-bold'>E-Bike & Fahrräder</p>
        <p className='font-bold'>Tel (+49) 03342.354 367</p>
     </h2>
   </div>
   <div className="flex justify-center items-center font-roboto xs:text-[13px] md:text-[17px] md:mx-[70px] xs:mx-5 mb-20 font-regular">
 <div className='grid xl:grid-cols-3 xs:grid-cols-1 md:grid-cols-2 xs:gap-5 md:gap-10 xl:gap-16'>
    <div className='flex justify-start items-start gap-2 flex-col'>
        <h3 className='text-bbf-green font-bold'>{currentLanguage==="de"?"Service-Center anderer Hersteller":currentLanguage==="pl"?"Centra serwisowe innych producentów":"Service-Center anderer Hersteller"}</h3>
        <p>{currentLanguage==="de"?"Der Service von Produkten folgender Marken erfolgt direkt über den jeweiligen Hersteller. Bitte senden Sie im Falle einer Reklamation diese direkt an die folgenden Service-Center. Im Normalfall beschleunigen Sie den Reklamationsprozess damit deutlich und Ihr Kunde hat eine schnellere Lösung.":currentLanguage==="pl"?"Produkty poniższych marek są serwisowane bezpośrednio przez danego producenta. W przypadku reklamacji prosimy o przesłanie jej bezpośrednio do poniższych centrów serwisowych. Zwykle znacznie przyspieszy to proces reklamacji i zapewni Państwa klientowi szybsze rozwiązanie.":"Der Service von Produkten folgender Marken erfolgt direkt über den jeweiligen Hersteller. Bitte senden Sie im Falle einer Reklamation diese direkt an die folgenden Service-Center. Im Normalfall beschleunigen Sie den Reklamationsprozess damit deutlich und Ihr Kunde hat eine schnellere Lösung."}</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
        <h3 className='text-bbf-green font-bold'>Busch & Müller – Licht</h3>
        <p>Busch & Müller KG</p>
        <p>Auf dem Bamberg 1</p>
        <p>58540 Meinerzhagen</p>
        <p>Tel.: 0 23 54 – 9 15 – 6000</p>
        <p>Fax: 0 23 54 – 9 15 – 7000</p>
        <p>info@bumm.de</p>
        <p>www.bumm.de</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Sigma – Fahrradcomputer, Pulsuhren und Batteriebeleuchtung</h3>
        <p>Sigma Elektro GmbH</p>
        <p>Dr.-Julius-Leber-Straße 15</p>
        <p>67433 Neustadt</p>
        <p>Tel.: 06321-9120-118</p>
        <p>Fax: 06321-9120-34</p>
        <p>Mail: service@sigmasport.com</p>
        <p>www.sigmasport.de</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>ABUS – Schlösser</h3>
        <p>August Bremicker Söhne KG</p>
        <p>Altenhofer Weg 25</p>
        <p>58300 Wetter</p>
        <p>Tel.: +49 (0) 2335/634-0</p>
        <p>Fax: +49 (0) 2335/634-300</p>
        <p>info@abus.de</p>
        <p>www.abus.de</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Continental AG  </h3>
        <p>Geschäftsbereich Zweiradreifen</p>
        <p>Continentalstr. 3-5</p>
        <p>34497 Korbach</p>
        <p>Tel.: 05631582241</p>
        <p>Fax: 05631582240</p>
        <p>Mail: service2rad@conti.de</p>
        <p>www.continental-reifen.de/fahrrad</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>SRAM und deren Marken: Sram, RockShox, Avid, Truvativ</h3>
        <p>SRAM Deutschland GmbH</p>
        <p>Romstr. 1</p>
        <p>97424 Schweinfurt</p>
        <p>Tel.: 0800 - 664 63 60 oder 09721 2930</p>
        <p>Mail: dsd.germany@sram.com</p>
        <p>www.sram.com</p>
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Allegion (vormals Trelock) – Schlösser, Licht</h3>
        <p>Trelock GmbH (Allegion)</p>
        <p>Johann-Krane-Weg 37</p>
        <p>48149 Münster</p>
        <p>Tel.: 0251/91999-0</p>
        <p>Fax: 0251/91999-50</p>
        <p>trelock@allegion.com</p>
        <p>www.trelock.de</p>
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Enviolo – Schaltung</h3>
        <p>Fallbrook Technologies International Co</p>
        <p>Service für D+AUS:</p>
        <p>Tel.: 0228 92 93 90 79</p>
        <p>de-service@enviolo.com</p>
        <p>www.enviolo.com</p>
     
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>SR SUNTOUR EUROPE GmbH – Federgabeln</h3>
        <p>Sales, Customer Support & Technical Services</p>
        <p>Riedstraße 31</p>
        <p>83627 Warngau</p>
        <p>Tel.: 08024 150 793-0</p>
        <p>Fax: 08024 150 793-29</p>
        <p>service@srsuntour-cycling.com</p>
        <p>www.srsuntour-cycling.com</p>
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>ANSMANN – E-Bike Antriebe</h3>
        <p>ANSMANN AG</p>
        <p>Industriestraße 10</p>
        <p>97959 Assamstadt</p>
        <p>Tel.: +49 (0) 6294 / 4204 – 5956</p>
        <p>e-bikeservice@ansmann.de</p>
        <p>www.ansmann.de</p>
   
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Pendix</h3>
        <p>Innere Schneeberger Str. 20</p>
        <p>08056 Zwickau</p>
        <p>Tel: +49(0)375 270 667 – 10</p>
        <p>info@pendix.de</p>
        <p>www.pendix.de</p>
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>STREETBOOSTER - Scooter</h3>
        <p>CryptoBoost GmbH</p>
        <p>Oststraße 25</p>
        <p>35745 Herborn</p>
        <p>Tel.; 02772-4692598</p>
        <p>Mail: info@streetbooster.de</p>
        <p>www.streetbooster.de</p>
   
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>BAFANG - E-Bike Antriebe</h3>
        <p>Bafang Electric GmbH</p>
        <p>Johannes-Liemke-Straße 2</p>
        <p>33161 Hövelhof</p>
        <p>Tel.: +49 (0) 5257 9357423</p>
        <p>Fax: +49 (0) 5257 9366869</p>
        <p>service.dach@bafang-e.com</p>
        <p>www.bafang-e.com</p>
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Schwalbe & Co GmbH</h3>
        <p>Otto Hahn Str.1</p>
        <p>51580 Reichshof</p>
        <p>Support - Center:</p>
        <p>Telefon 02265 109 0</p>
        <p>E-Mail:  info@schwalbe.com</p>
        <p>www.schwalbe.com</p>
   
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Tektro – Scheibenbremsen</h3>
        <p>Merida & Centurion Germany GmbH</p>
        <p>Blumenstraße 49-51</p>
        <p>71106 Magstadt</p>
        <p>Tel.: 0 71 59 – 94 59-600</p>
        <p>Fax: 0 71 59 – 94 59-500</p>
        <p>service@centurion.de</p>
        <p>www.centurion.de</p>
   
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>BOSCH – E-Bike Antriebe</h3>
        <p>BOSCH E-Bike Service</p>
        <p>Tel.: 0180 730 3003</p>
        <p>Mo.-Fr. 09:00 bis 17:00 Uhr</p>
        <p>Mail: ebikeservice@magura.de</p>
        <p>www.bosch-ebike.de</p>
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>Shimano, RST, Cateye-Teile, STePs Antrieb</h3>
        <p>Paul Lange & Co</p>
        <p>Hofener Straße 114</p>
        <p>70372 Stuttgart</p>
        <p>Tel.: 0711 – 258 802</p>
        <p>Fax: 0711 – 2588305</p>
        <p>Mail: info@paul-lange.de</p>
        <p>www.paul-lange.de</p>
   
       
    </div>
    <div className='flex justify-start items-start gap-2 flex-col'>
    <h3 className='text-bbf-green font-bold'>TranzX – Antrieb</h3>
        <p>JD Europe Components GmbH</p>
        <p>Salinenstr. 52</p>
        <p>61231 Bad Nauheim</p>
        <p>Tel.: 06032 – 92671-30</p>
        <p>Mo-Fr 8:00 – 17:00 Uhr</p>
        <p>Fax: 0 6032 92671-59</p>
        <p>info@tranzxpst.com</p>
        <p>www.tranzxpst.com</p>
   
       
    </div>

 </div>

   </div>
       
        </div>
  )
}

export default Support