import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { Button } from '../Components/Button';
const ContactPage = () => {
    const[message,setMessage]=useState("")
   const[name,setName]= useState("")
   const[email,setEmail]= useState("")
   const[phone,setPhone]=useState("")
   useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

const{t}= useTranslation()
    const handleSubmit =(e)=>{
     e.preventDefault()
 
if(!message||!name||!email||!phone){
  toast.error(t("enterallfields"), {
    position: toast.POSITION.TOP_CENTER
  });

  }
  else if(validator.isEmail(email)){
    toast.success(t("yourmessagesent"), {
      position: toast.POSITION.TOP_CENTER
    });

    axios
    .post("https://api.bbf-bike.de/api/mailer/bbf-bike", {
      name: name,
      email: email,
      subject:"BBF Bike Formular" ,
      nachricht: message,
      telephone: phone
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
      setName("")
      setEmail("")
      setPhone("")
      setMessage("")
  }
  else{
    toast.error(t("entervalidemail"), {
      position: toast.POSITION.TOP_CENTER
    });
  }    
    } 
  return (
    <div className='min-h-[calc(100vh-100px)] h-auto md:mb-[35px] xl:mb-[45px]  xs:mb-[25px] lg:mt-[110px] font-roboto'>
               
        <div className='xl:mt-60 md:mt-16 xs:mt-9 flex justify-evenly text-roboto lg:flex-row xs:flex-col-reverse xs:items-center  lg:items-start '>
        <form className='flex  flex-col items-start justify-start gap-10 xs:mt-10 md:mt-20 lg:mt-0'>
            <div className=' flex flex-col'>
            <label className=' font-semibold text-roboto xs:text-[13px] md:text-[17px]'> {t("name")}</label>
         <input type='text' className='border-bbf-green border-b-2 md:w-[400px] xs:w-[330px] h-10 p-2 focus:outline-none md:placeholder:text-[17px] xs:placeholder:text-[13px]'value={name} placeholder= {t("enteryourname")} onChange={(e)=>setName(e.target.value)}/>
            </div>
      
            <div className=' flex flex-col'>
            <label className=' font-semibold text-roboto xs:text-[13px] md:text-[17px]'>Email</label>
         <input type='text' className='border-bbf-green border-b-2 md:w-[400px] xs:w-[330px] h-10  p-2 focus:outline-none md:placeholder:text-[17px] xs:placeholder:text-[13px]' value={email} placeholder={t("enteryouremailid")} onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <div className='flex flex-col'>
            <label className=' font-semibold text-roboto xs:text-[13px] md:text-[17px]'>{t("phone")}</label>
         <input type='number' value={phone} className='border-bbf-green border-b-2 md:w-[400px] xs:w-[330px] h-10  p-2 focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none md:placeholder:text-[17px] xs:placeholder:text-[13px]'  placeholder={t("enteryourphonenumber")} onChange={(e)=>setPhone(e.target.value)}/>
            </div>
            <div className=' flex flex-col md:mb-5 xs:mb-2'>
            <label className='font-semibold text-roboto xs:text-[13px] md:text-[17px]'>{t("message")}</label>
         <textarea type='text' className='border-bbf-green border-b-2 md:w-[400px] xs:w-[330px] h-36  p-2 focus:outline-none md:placeholder:text-[17px] xs:placeholder:text-[13px]' value={message} placeholder={t("enteryourmessage")} onChange={(e)=>setMessage(e.target.value)}/>
            </div>
            {/* <button className='bg-bbf-green p-2 rounded-md text-bbf-white text-roboto font-bold hover:bg-opacity-80' onClick={handleSubmit}>{t("submit")}</button> */}
            <div onClick={handleSubmit}>
            <Button text={t("submit")}  color="bbf-black"/>
            </div>
            
            <ToastContainer/>
        </form>
        <div className=' flex flex-col justify-start items-start'>
       <h3 className='className="xs:text-[15px] md:text-[20px] xl:text-[25px] font-roboto font-bold  md:my-0 xs:my-4 underline underline-offset-[12px] xs:pb-2 md:pb-5 xl:pb-8 decoration-bbf-green'>{t("getintouch").toUpperCase()}</h3>
       <p className='mb-10 font-roboto xs:text-[13px] md:text-[17px] md:w-[500px] xs:w-[330px] text-justify'>{t("touchdes")}</p>

        {/* <img src='https://media.bbf-bike.de/logos/bbf.png' className='object-contain lg:h-80 lg:w-80 md:h-60 md:w-60 xs:h-24 xs:w-24 mb-10'/> */}
        <div className='flex items-center xs:mb-4 md:mb-10 gap-4'>
        <img width="50" height="50" 
        src="https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/64/external-phone-advertising-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png" alt="external-phone-advertising-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto" className='md:h-8 md:w-8 xs:h-7 xs:w-7 object-contain'/>
        <h3 className=' font-semibold text-roboto xs:text-[13px] md:text-[17px]'>+49 (0) 3342.354.325</h3>
        </div>
        <div className='flex items-center gap-4'>
        <img width="50" height="50" 
        src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-email-advertising-kiranshastry-lineal-kiranshastry-7.png" alt="external-email-advertising-kiranshastry-lineal-kiranshastry-7" className='md:h-8 md:w-8 xs:h-7 xs:w-7 object-contain'/>
        <h3 className='font-semibold text-roboto xs:text-[13px] md:text-[17px]'>info@bbf-bike.de</h3>
        </div>
        </div>
        </div>
        </div>
  )
}

export default ContactPage